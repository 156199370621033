
<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button :icon="Plus" type="primary" @click="handleEdit()">
          创建自定义新闻
        </el-button>
        <!-- <el-button type="primary" @click="handleEdit($event)">
          同步公众号文章链接 -->
        <!-- </el-button> -->
        <!-- <el-button type="primary" @click="handleEdit($event)">
          同步公众号推文
        </el-button> -->
      </vab-query-form-left-panel>

      <vab-query-form-right-panel :span="16">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="新闻标题">
            <el-input type="text" placeholder="请输入新闻标题" v-model="queryForm.title"></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建状态">
            <el-select v-model="queryForm.articleCategoryId" clearable>
              <template v-for="(item, index) in statusList" :key="index">
                <el-option :value="item.id" :label="item.name"></el-option>
              </template>
            </el-select>
          </el-form-item> -->
          <el-form-item label="发布状态">
            <el-select v-model="queryForm.status" clearable>
              <template v-for="(item, index) in showList" :key="index">
                <el-option :value="item.id" :label="item.name"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="序号" type="index" />
      <el-table-column label="新闻标题" prop="title" show-overflow-tooltip />
      <el-table-column label="创建方式" prop="title" show-overflow-tooltip />
      <el-table-column label="创建时间" prop="title" show-overflow-tooltip>
        <template #default="{ row }">
          {{ handleDate(row.createTime)}}
        </template>
      </el-table-column>

      <el-table-column label="创建状态" prop="title" show-overflow-tooltip />
      <el-table-column label="排序" prop="sort">
        <template #default="{ row }">
          <el-input v-model="row.sort" type="number" @blur="changeSort($event, row)" />
        </template>
      </el-table-column>
      <el-table-column label="发布状态" prop="signNum" width='200'>
        <template #default="{ row }">
          <el-switch v-model="row.isShow" class="ml-2" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ccc" active-text="显示" inactive-text="隐藏" @change="changeStatus($event, row)" />
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="250">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button type="primary" @click="goConfig(row)">
            配置
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
  onDeactivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import {
  getNewsList,
  deleteArticle,
  getCategoryList,
  updateNewsSort,
  newsSaveOrUpdate,
} from '@/api/content'
import { getUserEnterPriseAccount } from '@/api/user'
import { parseTime } from '@/utils/index';
export default defineComponent({
  name: 'newsList',
  components: {
    Edit: defineAsyncComponent(() => import('./components/newsEdit')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        code: '',
        pageNum: 1,
        pageSize: 20,
        status: true,
        title: '',
        type: 'OFFICIAL_WEBSITE',
      },
      total: 0,
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      categoryList: [],
      enterPriseList: [],
      statusList: [
        {
          name: '全部',
          id: 1,
        },
        {
          name: '创建成功',
          id: 2,
        },
        {
          name: '同步中',
          id: 3,
        },
        {
          name: '同步失败',
          id: 4,
        },
      ],
      showList: [
        {
          name: '全部',
          id: '',
        },
        {
          name: '显示',
          id: true,
        },
        {
          name: '隐藏',
          id: false,
        },
      ],
    })

    const handleEdit = (row) => {
      state.editRef.showEdit(row)
    }

    const handleDelete = (row) => {
      if (row.newsCode) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          row.delFlag = true
          const { msg } = await newsSaveOrUpdate(row)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getNewsList(state.queryForm)
      state.list = data.data
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }

    onActivated(async () => {
      let customInfo = localStorage.getItem('customInfo')
      let tenantKey = ''
      if (customInfo != undefined && customInfo != 'undefined') {
        customInfo = JSON.parse(customInfo)
        if (!!customInfo.tenantKey) {
          tenantKey = customInfo.tenantKey
        } else {
          tenantKey = ''
        }
      }
      if (tenantKey == 'GDC') {
        state.queryForm.type = 'GDC_OFFICIAL_WEBSITE'
      }
      await fetchData()
      console.log('我执行了')
    })

    const goConfig = (val) => {
      proxy.$router.push({
        path: '/website/newsEdit',
        query: {
          newsCode: val.newsCode,
        },
      })
    }

    const changeSort = async (e, item) => {
      const { data } = updateNewsSort({ params: item })
      console.log(data)
    }

    const handleDate = (val) => {
      return parseTime(val)
    } 

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      goConfig,
      Plus,
      changeSort,
      handleDate
    }
  },
})
</script>
<style lang="scss" scoped></style>
